import { Navigate, type RouteObject } from 'react-router-dom'
import { ErrorBoundary } from '@trinity/components'

export const styleflowRoutes: RouteObject = {
  path: 'styleflow',
  errorElement: <ErrorBoundary />,
  children: [
    { index: true, element: <Navigate replace to='collections' /> },
    {
      path: 'collections',
      children: [
        {
          index: true,
          async lazy() {
            const { Collections, loader, action } = await import('./collections')
            return { Component: Collections, loader, action }
          },
        },
        {
          path: ':collectionId',
          async lazy() {
            const { Collection, loader, action } = await import('./collections.$id')
            return { Component: Collection, loader, action }
          },
        },
      ],
    },
    {
      path: 'visualize',
      id: 'visualize',
      async lazy() {
        const { Visualize, loader } = await import('./visualize')
        return { Component: Visualize, loader }
      },
      children: [
        {
          index: true,
          async lazy() {
            const { VisualizeIndex, action } = await import('./visualize.index')
            return { Component: VisualizeIndex, action }
          },
        },
        {
          path: 'garmentType',
          async lazy() {
            const { GarmentType, action } = await import('./visualize.garmentType')
            return { Component: GarmentType, action }
          },
        },
        {
          path: 'fabric',
          async lazy() {
            const { VisualizeFabric, loader, action } = await import('./visualize.fabric')
            return { Component: VisualizeFabric, loader, action }
          },
        },
        {
          path: 'lining',
          async lazy() {
            const { VisualizeLining, loader, action } = await import('./visualize.lining')
            return { Component: VisualizeLining, loader, action }
          },
        },
        {
          path: 'button',
          async lazy() {
            const { VisualizeButton, loader } = await import('./visualize.button')
            return { Component: VisualizeButton, loader }
          },
        },
        {
          path: 'ribknit',
          async lazy() {
            const { VisualizeRibknit, loader } = await import('./visualize.ribknit')
            return { Component: VisualizeRibknit, loader }
          },
        },
        {
          path: 'thread',
          async lazy() {
            const { VisualizeThread, loader } = await import('./visualize.thread')
            return { Component: VisualizeThread, loader }
          },
        },
        {
          path: 'zipper',
          async lazy() {
            const { VisualizeZipper, loader } = await import('./visualize.zipper')
            return { Component: VisualizeZipper, loader }
          },
        },
      ],
    },
    {
      path: 'rack',
      async lazy() {
        const { Rack, loader, action } = await import('./rack')
        return { Component: Rack, loader, action }
      },
    },
  ],
}
