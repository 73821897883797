import { motion } from 'motion/react'
import { Box } from '@mui/material'

const iconVariants = {
  top: {
    close: { rotate: 135, y: 3, x: -3 },
    open: { rotate: 0, y: 0 },
  },
  bottom: {
    close: { rotate: 45, y: -7, x: -3 },
    open: { rotate: 0, y: 0 },
  },
}

interface AnimatedIconProps {
  open: boolean
  side: keyof typeof iconVariants
  top: number
}

function AnimatedIcon({ open, side, top }: AnimatedIconProps) {
  return (
    <Box
      component={motion.div}
      initial='close'
      animate={open ? 'close' : 'open'}
      variants={iconVariants[side]}
      sx={{
        position: 'absolute',
        right: 29,
        top: top,
        width: 18,
        height: 2,
        bgcolor: 'grey.500',
        zIndex: 'modal',
      }}
    />
  )
}

const menuVariant = {
  visible: (i: number) => ({ opacity: 1, y: 0, transition: { ease: 'easeOut', delay: i * 0.1, duration: 0.25 } }),
  hidden: { opacity: 0, y: -20 },
}

interface AnimatedMenuProps {
  index: number
  children: React.ReactNode
}

function AnimatedMenu({ index, children }: AnimatedMenuProps) {
  return (
    <motion.div custom={index} initial='hidden' animate='visible' variants={menuVariant}>
      {children}
    </motion.div>
  )
}

export { AnimatedIcon, AnimatedMenu }
