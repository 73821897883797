import { Suspense } from 'react'
import { Navigate, Outlet, createBrowserRouter, useLoaderData } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { GlobalContainer, useGlobalState, ErrorBoundary, FullPageSpinner, TrinityWrapper } from '@trinity/components'
import { checkAuth, type UseLoaderData } from '@trinity/utils'
import { NavBar } from 'components'
import { clientRoutes } from './clients'
import { fabricsRoutes } from './fabric-explorer'
import { libraryRoutes } from './library'
import { ordersRoutes } from './orders'
import { priceListRoutes } from './price-list'
import { newOrderRoutes } from './new-order'
import { paymentRoutes } from './payments'
import { styleflowRoutes } from './styleflow'
import { reportsRoutes } from './reports'

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

export const router = sentryCreateBrowserRouter([
  {
    path: '/',
    loader,
    element: <Layout />,
    children: [
      { index: true, element: <Navigate replace to='dealer-home' /> },
      ordersRoutes,
      clientRoutes,
      fabricsRoutes,
      libraryRoutes,
      newOrderRoutes,
      paymentRoutes,
      priceListRoutes,
      styleflowRoutes,
      reportsRoutes,
      {
        path: '*',
        element: <h1>Page Not Found</h1>,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
])

async function loader() {
  const user = await checkAuth('dealer')

  return user
}

function Layout() {
  const user = useLoaderData() as UseLoaderData<typeof loader>

  return (
    <TrinityWrapper appName='dealer' user={user}>
      <nav>
        <NavBar />
      </nav>
      <main>
        <GlobalContainer id='root-container'>
          <Suspense fallback={<FullPageSpinner />}>
            <Outlet />
          </Suspense>
        </GlobalContainer>
      </main>
    </TrinityWrapper>
  )
}

export function PrivateRoute({ permission, children }: { permission: string; children: JSX.Element }) {
  const { user } = useGlobalState()

  if (user?.permissions.nav.includes(permission)) {
    return children
  }

  return <h1>Page Not Found</h1>
}
